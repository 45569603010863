.CalendarDay__highlighted_calendar {
  background: #e9f5ff;
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #7fb7de;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #4d93cf;
}
